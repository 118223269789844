import * as S from './RegisterYourInterestForm.styled'
import * as G from '../../styles/global.styles'
import {
  FormFieldProps,
  FormsProps,
  SelectOption
} from '../../services/api/types'
import {
  getChoices,
  getFormInitValues,
  dynamicValidation
} from '../../services/utils'
import {
  SVGIcon,
  IconEnums,
  TextInput,
  DropdownStyled,
  TextAreaInput,
  Radio,
  CheckBox,
  BlockTitle,
  GymsSelect,
  FileInput,
  DatePickerStyled
} from '../base'
import { Col, Container, Row } from '../../styles/grid.styles'
import { RECAPTCHA_KEY } from '../../services/api/constants'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { submitForm, submitFormWithFiles } from '../../services/api/cms'
import { setCookie } from '../../services/api/cookies'
import { SiteContext } from '../../services/context/SiteContext'
import useGymOptions from '../../hooks/useGymOptions'

export interface RegisterYourInterestFormProps extends FormsProps {
  downloadFileOnSuccess?: boolean
  fileUrl?: string
  preferredLocation?: {
    state: string
    suburb: string
  }
  onClose?: () => void
  fields: FormFieldProps[]
}

export const RegisterYourInterestForm = (
  props: RegisterYourInterestFormProps
) => {
  const appContext = useContext(SiteContext)

  const {
    fields,
    title,
    preamble,
    submitText,
    formAction,
    slug,
    onClose,
    downloadFileOnSuccess,
    preferredLocation,
    fileUrl
  } = props
  const { gymOptions } = useGymOptions('', '', fields, props.slug)

  const [responseMessage, setResponseMessage] = useState('')
  const [submitting, setSubmitting] = useState<boolean | null>(null)
  const [success, setSuccess] = useState<boolean | null>(null)

  let fileFieldText: Array<string> = []
  fields &&
    fields.length > 0 &&
    fields.map(field => {
      if (field.type === 'file') {
        fileFieldText.push(field.name)
      }
    })
  const hasFile = fileFieldText && fileFieldText.length > 0

  const formik = useFormik({
    initialValues: {
      ...getFormInitValues(props.fields),
      state: preferredLocation?.state,
      'preferred-location': preferredLocation?.suburb
    },
    validationSchema: dynamicValidation(props.fields),
    onSubmit: async values => {
      setSubmitting(true)
      let fileData: any
      let fieldData: any
      if (hasFile) {
        fileData = Object.keys(values)
          .filter(key => fileFieldText.includes(key))
          .reduce((obj: any, key: string) => {
            obj[key] = values[key]
            return obj
          }, {})
        fieldData = Object.keys(values)
          .filter((key: string) => !fileFieldText.includes(key))
          .reduce((obj: any, key: string) => {
            obj[key] = values[key]
            return obj
          }, {})
      }
      if (!slug) {
        return
      }

      let res = hasFile
        ? await submitFormWithFiles(null, slug, fieldData, fileData)
        : await submitForm(null, slug, values)

      if (res.ok) {
        setResponseMessage(
          fileUrl
            ? res.success_message || 'Your download will start soon.'
            : res.success_message ||
                'Thank you for registering your interest! We have received your submission and will be in touch with you soon.'
        )
        setCookie('isDownloadable', 'true', 7)
        if (downloadFileOnSuccess && fileUrl) {
          downloadFile(fileUrl)
        } else {
          setSuccess(true)
          setSubmitting(false)
        }
      } else {
        setResponseMessage(res.error_message)
        setSuccess(false)
        setSubmitting(false)

        throw responseMessage
      }
    }
  })

  function getFileNameFromURL(url: string): string {
    const parts: string[] = url.split('/')
    const fileName: string = parts.pop() || ''
    return fileName
  }

  function downloadFile(url: string): void {
    setSubmitting(true)
    const newDomain = 'staging.plusfitness.com.au'
    const newUrl = url.replace('staging.plus.fitness', newDomain)
    fetch(newUrl)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = getFileNameFromURL(url)
        document.body.appendChild(link)
        link.dispatchEvent(new MouseEvent('click'))
        link.parentNode?.removeChild(link)
        URL.revokeObjectURL(link.href)
        setSubmitting(false)
        setSuccess(true)
      })
      .catch(error => {
        setSuccess(false)
        setSubmitting(false)
        console.error('Error downloading file:', error)
      })
  }

  const dropdownComponents = {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => (
      <span style={{ position: 'relative', right: 6 }}>
        <SVGIcon name={IconEnums['icon-angle-down']} />
      </span>
    )
  }

  const hasRecaptcha =
    fields &&
    fields.length > 0 &&
    fields?.find(it => it.field_block === 'recaptcha')
      ? true
      : false

  const [recaptchaDisableSubmit, setRecaptchaDisableSubmit] = useState(
    hasRecaptcha
  )

  const closeModal = () => {
    if (success) {
      appContext.setSiteInfor &&
        appContext.setSiteInfor({
          ...appContext.siteInfor,
          isDownloadable: true
        })
    }
    onClose && onClose()
  }

  useEffect(() => {
    if (!window.RECAPTCHA_INITIALIZED && hasRecaptcha) {
      loadReCaptcha(RECAPTCHA_KEY)
      window.RECAPTCHA_INITIALIZED = true
    }
  }, [hasRecaptcha])

  useEffect(() => {
    preferredLocation && formik.setFieldValue('state', preferredLocation.state)
    preferredLocation &&
      formik.setFieldValue('preferred-location', preferredLocation.suburb)
  }, [preferredLocation])

  return (
    <S.FormWrapper>
      <Container>
        {onClose && (
          <S.CloseWrapper onClick={closeModal}>
            <SVGIcon name={IconEnums.close} />
          </S.CloseWrapper>
        )}
        {success && !submitting ? (
          <S.Success>
            <S.SuccessIcon
              src='/static/svgs/icon-check-white.svg'
              alt='check'
            />
            <S.SuccessTitle>SUCCESS</S.SuccessTitle>
            <S.SuccessMsg>{responseMessage}</S.SuccessMsg>
            <S.ButtonSubmit
              type='button'
              style={{ minWidth: 224, justifyContent: 'center' }}
              onClick={closeModal}
            >
              Close
            </S.ButtonSubmit>
          </S.Success>
        ) : (
          <>
            {title && <S.Heading>{title}</S.Heading>}
            {preamble && <S.PreFormText>{preamble}</S.PreFormText>}
            <S.Form action={formAction} onSubmit={formik.handleSubmit}>
              <Row gutter={8}>
                {fields?.map((field, idx) => {
                  let inputComp
                  const isInvalid =
                    formik &&
                    formik.errors[field.name] &&
                    formik.touched[field.name]
                      ? true
                      : false
                  switch (field.type) {
                    case 'text_area': {
                      switch (field.field_block) {
                        case 'label':
                          return (
                            <S.FormGroup col={1} key={idx}>
                              <S.FormLabel error={isInvalid}>
                                {field.label}
                              </S.FormLabel>
                            </S.FormGroup>
                          )
                        case 'heading':
                          return (
                            <BlockTitle isYouFitness>{field.label}</BlockTitle>
                          )
                        case 'multiline':
                          inputComp = (
                            <TextAreaInput
                              rows={4}
                              cols={28}
                              name={field.name}
                              borderColor={isInvalid ? 'red' : undefined}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          )
                          break
                      }
                      break
                    }
                    case 'select': {
                      const selectOptions = getChoices(field.choices)
                      if (field.field_block == 'gym') {
                        inputComp = (
                          <GymsSelect
                            classNamePrefix={
                              isInvalid ? 'error you-fitness' : 'you-fitness'
                            }
                            placeholder={field.help_text}
                            name={field.name}
                            defaultOptions={gymOptions}
                            onChange={(option: SelectOption) =>
                              formik.setFieldValue(field.name, option.value)
                            }
                            onBlur={formik.handleBlur}
                            formSlug={props.slug}
                            components={dropdownComponents}
                            maxMenuHeight={160}
                          />
                        )
                      } else {
                        inputComp = (
                          <DropdownStyled
                            classNamePrefix={
                              isInvalid ? 'error you-fitness' : 'you-fitness'
                            }
                            placeholder={field.help_text}
                            name={field.name}
                            options={selectOptions}
                            components={dropdownComponents}
                            defaultValue={
                              field.name === 'state'
                                ? {
                                    label: preferredLocation?.state,
                                    value: preferredLocation?.state
                                  }
                                : null
                            }
                            onChange={(option: SelectOption) => {
                              formik.setFieldValue(field.name, option.value)
                            }}
                            onBlur={formik.handleBlur}
                            maxMenuHeight={160}
                          />
                        )
                      }
                      break
                    }
                    case 'checkbox': {
                      const selectOptions = getChoices(field.choices)
                      inputComp = (
                        <>
                          {selectOptions.map(op => {
                            return (
                              <CheckBox
                                name={field.name}
                                type={field.type}
                                value={op.value}
                                label={op.label}
                                color='black'
                                borderColor={isInvalid ? 'red' : 'white'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className='you-fitness'
                              />
                            )
                          })}
                        </>
                      )
                      break
                    }
                    case 'radio': {
                      const selectOptions = getChoices(field.choices)
                      inputComp = (
                        <>
                          {selectOptions.map(op => {
                            return (
                              <Radio
                                name={field.name}
                                type={field.type}
                                value={op.value}
                                label={op.label}
                                color='black'
                                borderColor={isInvalid ? 'red' : 'white'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            )
                          })}
                        </>
                      )
                      break
                    }
                    case 'file': {
                      inputComp = (
                        <FileInput
                          name={field.name}
                          type={field.type}
                          text={field.label}
                          className={`you-fitness-fileinput ${
                            isInvalid ? 'you-fitness-error' : ''
                          }`}
                          color='black'
                          borderColor={isInvalid ? 'red' : 'white'}
                          onChange={(event: any) =>
                            formik?.setFieldValue(
                              field.name,
                              event.currentTarget.files[0]
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                      )
                      break
                    }
                    case 'text': {
                      if (field.field_block == 'date') {
                        inputComp = (
                          <DatePickerStyled
                            name={field.name}
                            setFieldValue={formik?.setFieldValue}
                            showYearDropdown
                          />
                        )
                      } else if (field.field_block == 'datetime') {
                        inputComp = (
                          <DatePickerStyled
                            timePicker={true}
                            name={field.name}
                            setFieldValue={formik?.setFieldValue}
                            showYearDropdown
                          />
                        )
                      } else {
                        inputComp = (
                          <TextInput
                            placeholder={field.help_text}
                            name={field.name}
                            type={field.type}
                            color='black'
                            borderColor={isInvalid ? 'red' : 'white'}
                            defaultValue={
                              field.name === 'preferred-location'
                                ? preferredLocation?.suburb
                                : undefined
                            }
                            onChange={(e: any) => {
                              formik.setFieldValue(field.name, e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                          />
                        )
                      }
                      break
                    }
                    case 'input': {
                      if (field.field_block == 'recaptcha') {
                        inputComp = (
                          <ReCaptcha
                            sitekey={RECAPTCHA_KEY}
                            action={formAction || 'submit'}
                            verifyCallback={async (token: any) => {
                              if (formik.values['recaptcha'] !== token)
                                await formik.setFieldValue(
                                  'recaptcha',
                                  token,
                                  false
                                )
                              setRecaptchaDisableSubmit(false)
                            }}
                          />
                        )
                      } else {
                        inputComp = (
                          <TextInput
                            placeholder={field.help_text}
                            name={field.name}
                            type={field.type}
                            color='black'
                            borderColor={isInvalid ? 'red' : 'white'}
                            onChange={(e: any) => {
                              formik.setFieldValue(field.name, e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                          />
                        )
                      }
                      break
                    }
                    default:
                      inputComp = (
                        <TextInput
                          placeholder={field.help_text}
                          name={field.name}
                          type={field.type}
                          color='black'
                          borderColor={isInvalid ? 'red' : 'white'}
                          onChange={(e: any) => {
                            formik.setFieldValue(field.name, e.target.value)
                          }}
                          onBlur={formik.handleBlur}
                        />
                      )
                      break
                  }
                  return (
                    <Col md={6}>
                      <S.FormGroup
                        style={{
                          display:
                            field.field_block === 'recaptcha' ||
                            field.type === 'hidden'
                              ? 'none'
                              : undefined
                        }}
                      >
                        <S.FormLabel
                          hiddenMobile={field.type === 'checkbox'}
                          error={
                            formik.errors[field.name] &&
                            formik.touched[field.name] &&
                            formik.errors[field.name]
                              ? true
                              : false
                          }
                        >
                          {field.type === 'checkbox' &&
                          getChoices(field.choices).length === 1 ? (
                            <>&nbsp;</>
                          ) : (
                            field.label
                          )}
                        </S.FormLabel>
                        {inputComp}
                        <S.ErrorMessage>
                          {formik?.errors[field.name] &&
                            formik?.touched[field.name] &&
                            formik?.errors[field.name]}
                        </S.ErrorMessage>
                      </S.FormGroup>
                    </Col>
                  )
                })}
              </Row>

              {submitting ? (
                <G.SpinnerWrap isYouFitness>
                  <G.Spinner />
                </G.SpinnerWrap>
              ) : (
                <S.ButtonWrap>
                  <S.ButtonCancel type='button' onClick={onClose}>
                    Cancel
                  </S.ButtonCancel>
                  <S.ButtonSubmit
                    type='submit'
                    disabled={submitting || recaptchaDisableSubmit}
                  >
                    {submitText || 'Send Enquiry'}
                  </S.ButtonSubmit>
                </S.ButtonWrap>
              )}
            </S.Form>
          </>
        )}
      </Container>
    </S.FormWrapper>
  )
}

export default RegisterYourInterestForm
