import styled from 'styled-components'
import tw from 'twin.macro'
import { fontSize, size } from '../../../styles/theme'

export const ContentWrap = styled.div<{ isContactPage?: boolean }>``

export const Content = styled.div`
  ${tw`text-white__deep`}

  .richtext-image {
    &.right {
      margin: 0 0 0 auto;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`text-white`}
  }

  a {
    ${tw`text-blue__deep`}
  }

  ul {
    ${tw`pl-5`}
    list-style: disc;
  }

  ol {
    ${tw`pl-5`}
    list-style: number;
  }

  p,
  b {
    word-break: break-word;
  }

  .full-width {
    ${tw`w-full`}
  }
`

export const ReadMore = styled.span`
  ${tw`pt-6 pb-2 inline-block font-medium cursor-pointer text-blue__deep hover:underline`}
`

export const GymCompMainSectionLink = styled.a`
  ${tw`flex items-center text-white border border-white`}
    line-height: 2.5;
    padding: 0.35rem 2rem;
    font-weight: 500;
    ${fontSize(14)} 
    margin-top: ${size(20)};

    @media (max-width: 1100px) {
      ${tw`mx-auto`}
    }

    @media (max-width: 480px) {
      margin-top: ${size(10)}
      padding: ${size(4)} ${size(24)};
      line-height: 2;
    }
`

export const GymCompMainSectionRight = styled.div<{ custom?: boolean }>`
  width: 45%;
  ${tw`bg-white flex items-center justify-center flex-col font-bold relative`}
  ${fontSize(30)}

  @media (max-width: 1100px) {
    display: none;
  }

  p {
    ${tw`w-full mx-auto text-center`}
    max-width: ${size(300)};
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    right: 100%;
    top: -2px;
    border-style: solid;
    border-width: 340px 120px 0 0;
    border-color: transparent #FFF transparent transparent;
    z-index: 49;
    
    @media (max-width: 1100px) {
      display: none;
    }
  }

  ${({ custom }) => {
    return custom
      ? `
      background: #000000; 
      color: #FFF;
      
        &:before {
          content: '';
          position: absolute;
          width: 30%;
          height: 100%;
          right: -30%;
          top: 0;
          border-style: solid;
          border-width: 340px 100px 0 0;
          border-color: transparent #000 transparent transparent;
          z-index: 49;
          transform: rotate(180deg);
        }
      `
      : ``
  }}
`

export const GymBodyMainSection = styled.div`
  padding-top: ${size(56)}
  padding-bottom: ${size(62)}
`

export const GymCompMainSection = styled.div<{ custom?: boolean }>`
  ${tw`flex w-full`}
  margin-bottom: ${size(10)};
  overflow-y: hidden;
  
  @media (max-width: 1100px) {
    ${tw`flex-col items-center relative`}
    margin-bottom: ${size(20)};
  }

  @media (max-width: 480px) {
    margin-bottom: ${size(10)};
  }  
  ${({ custom }) => {
    return custom ? `` : `flex-direction: row-reverse;`
  }}
  
`

export const GymCompMainSectionLeft = styled.div`
  width: 55%;
  @media (max-width: 1100px) {
    ${tw`relative`}
  }
  @media (max-width: 992px) {
    width: 100%;
    height: ${size(280)};
  }
  @media (max-width: 480px) {
    width: 100%;
    height: ${size(180)};
  }
  img {
    height: ${size(253)};
  }
`

export const GymCompMainSectionLeftCustom = styled.div`
  display: none;

  @media (max-width: 1100px) {
    ${tw`w-full h-full absolute flex flex-col items-center justify-center`}
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url(.png);
  }

  p,
  span {
    ${tw`text-white text-center`}
  }

  p {
    ${tw`font-bold w-full mx-auto`}
    ${fontSize(24)}
    
    @media (max-width: 480px) {
      margin-bottom: ${size(10)};
      max-width: ${size(250)};
      line-height: 1.3;
    }
  }

  span {
    @media (max-width: 480px) {
      margin-bottom: 0;
      ${fontSize(13)}
    }
  }
`
