import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { fontSize, size } from '../../../styles/theme'
import { SVGIcon } from '../../base/icon/svgIcon'

export const GymInformation = styled.div<{ src: string }>`
  ${tw`flex content-between`}
  
  @media (max-width: 884px) {
    ${tw`flex-col items-center`}
  }

  .left, .right {
    width: 50%;
    
    @media (max-width: 992px) {
      width: 100%;
    }
    
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  
  .right {
    padding-left: ${size(23)};
    position: relative;
    
    @media (max-width: 1100px) {
      padding-left: 0;
      padding: 0 24px;
    }
    
    @media (max-width: 480px) {
      padding: 0 24px;
    }
  }
  
  .left {
    .wrap {
      padding: 26px 24px;
      ${tw`bg-blue__deep text-white`}
    }  

    .title {
      ${tw`font-bold relative uppercase font-youfitnessHeading`}
      font-size: ${size(30)};
      padding-bottom: ${size(20)};

      &::before {
        content: "";
        width: ${size(70)};
        height: ${size(4)};
        ${tw`bg-white absolute`};
        bottom: 0;
        left: 0;
      }
    }

    .address {
      font-size: ${size(14)};
      
      span {
        ${tw`uppercase`}
      }
    }

    .phone-email {
      ${tw`flex items-center flex-wrap`}
      margin-top: ${size(20)}
      font-size: ${size(14)};

      @media (max-width: 480px) {
        ${tw`flex-col items-start`}
        margin-top: ${size(20)}
        margin-bottom: ${size(24)}
      }
      
      a {
        margin-bottom: ${size(24)}

        &:first-child {
          padding-right: ${size(24)};
        }
        
         @media (max-width: 480px) {
           margin-bottom: ${size(2)}
         }
         
         span {
          text-decoration: underline;
         }
      }

      &.franchisee {
        @media (max-width: 900px) {
          margin-bottom: ${size(18)};
        }

       a {
        margin-bottom: ${size(5)};
        padding-right: ${size(24)};

        @media (max-width: 900px) {
          padding-right: ${size(24)};
        }
        
        &:last-child {
          margin-bottom: ${size(24)};

          @media (max-width: 900px) {
            margin-bottom: ${size(5)};
          }
         }
       }
      }
      
     
    }

    .buttons {
      ${tw`flex items-center flex-wrap`}

      .button-map, .button-direction, .button-virtual {
        ${tw`mr-4`}  
      }

      @media (max-width: 884px) {
        ${tw`flex-row  items-center`}

      }      
      
      .button-map, .button-direction, .button-virtual {
        max-width: 180px;
        ${tw`items-center w-full`}  
      }
      
      @media (max-width: 430px) {
        ${tw`justify-center`}
      }

      .button-map {
        ${tw`my-4`}  
      }

      .button-virtual {
        a {
          max-width: 180px;
          ${tw`w-full`}  
        }
      }

      a {
        @media (max-width: 992px) {
          ${tw`flex items-center justify-center`}
        }
        
        @media (max-width: 480px) {
          white-space: nowrap;
        }
      }
    }
  }
`

export const GymNewLayoutMap = styled.div`
  ${tw`w-full`}
  overflow: hidden;
  height: ${size(310)};
`

export const GymInFoButton = styled.button`
  ${tw`flex items-center text-white border border-white`}
  line-height: 2.5;
  padding: 0.35rem 2rem;
  font-weight: 500;
  ${fontSize(14)} 
  margin-top: ${size(20)}
  
  @media (max-width: 1100px) {
    ${tw`mx-auto`}
  }
`

export const GymInFoTitle = styled.div`
  ${fontSize(30)}
  ${tw`text-white font-bold flex w-full items-center relative`}
  padding-bottom: ${size(22)};
  height: ${size(110)};
  
   &::before {
     content: "";
     width: ${size(70)};
     height: ${size(4)};
     ${tw`bg-blue__deep absolute`};
     bottom: 20px;
     left: 0;
     
     @media (max-width: 480px) {
        bottom: 0;
     }
   }
  
  span {
    ${tw`text-blue__deep font-bold`}  
    margin: 0 5px;
  }  
  
  .group {
    ${tw`flex items-center`}
    
    @media (max-width: 768px) {
      height: ${size(40)};
    }
  }
  
   @media (max-width: 480px) {
      height: auto;
      ${tw`flex-col items-start`}
      margin-bottom: ${size(41)}
      margin-top: ${size(52)}
   }
   
`

export const WrapFlip = styled.div<{ labels?: number }>`
  position: relative;
  overflow: hidden;
  width: 180px;
  height: 40px;

  p {
    position: absolute;
    top: ${size(-2.5)};
    margin-bottom: 0;
    text-transform: uppercase;

    &.flip {
      transform: rotateX(360deg);
      transition: transform 0.6s;
    }
  }
`

export const GymInFoDescShadow = styled.div<{ isReadMore?: boolean }>`
  ${tw`absolute w-full bottom-0 transition duration-500`}
  height: ${size(112)};
  background: linear-gradient(180deg, rgba(27, 27, 27, 0) 0%, #1B1B1B 100%);

  @media (max-width: 767px) {
    bottom: -15px;
  }

  ${({ isReadMore }) =>
    !isReadMore &&
    css`
      opacity: 0;
    `}
`

export const GymInFoDescWrap = styled.div<{ isTouching?: boolean }>`
  ${tw`relative`}

  @media (min-width: 1025px) {
    &:hover {
      ${GymInFoDescShadow} {
        opacity: 0;
        z-index: -1;
      }
    }
  }

  @media (max-width: 1024px) {
    ${({ isTouching }) =>
      isTouching &&
      css`
        ${GymInFoDescShadow} {
          z-index: -1;
        }
      `}
  }
`

export const GymInFoDesc = styled.div<{
  isReadMore?: boolean
  height?: number
}>`
  ${tw`text-white `}
  line-height: ${size(24)};
  transition: all 0.5s;
  overflow: hidden;
  position: relative;
  margin-bottom: 0;

  a, h3 {
    ${tw`text-blue__deep`}
  }

  h3 {
    font-size: ${size(30)}
    ${tw`leading-9`}
  }
  
  ${({ isReadMore, height }) => {
    return isReadMore ? `height: ${size(424)}` : `height: ${size(height)}`
  }}
`

export const btnIcon = styled.figure``

export const Icon = styled(SVGIcon)`
  ${tw`hover:text-orange text-white relative`}
  margin-right: ${size(8)}
  top: ${size(1)}
`

export const OpenTime = styled.div`
  ${tw`mb-4`}
`

export const OpenTimeTitle = styled.div`
  ${tw`font-bold mb-1`}
`

export const OpenTimeLine = styled.div`
  ${tw`flex`}
`

export const WeekDayText = styled.div`
  ${tw`w-1/2 pr-4 text-sm`}
`

export const WeekDayTime = styled.div`
  ${tw`text-sm`}
`
export const IframeWrapper = styled.iframe`
  height: 10rem;
  @media (min-width: 768px) {
    height: 20rem;
  }
  @media (min-width: 1024px) {
    height: 26rem;
  }
  ${tw`w-full`}
`

export const ButtonAction = styled.div<{ isFirst?: boolean }>`
  ${tw`w-full bg-white p-0`}
  max-width: 160px;

  a {
    ${tw`bg-white text-blue__deep p-0`}
    font-size: ${size(14)}
    font-style: normal;
    margin-right: 0 !important;
    padding: 10px 0;

    img {
      height: 21px;
    }

    &:hover {
      background-color: #cccccc;
    }
  }

`
