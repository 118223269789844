import styled from 'styled-components'
import tw from 'twin.macro'
import { fontSize, size } from '../../../styles/theme'

export const TrainerWrap = styled.div`
  ${tw`w-full flex justify-center`}

  @media (max-width: 768px) {
    ${tw`flex-col`}
  }
`

export const TrainerBlock = styled.div`
  width: 50%;
  max-width: ${size(218)};
  margin: 0 ${size(30)};

  @media (max-width: 768px) {
    width: 90%;
    max-width: none;
    margin: 0 auto;
    margin-bottom: ${size(50)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const TrainerImage = styled.div`
  ${tw`cursor-pointer`}
  height: ${size(218)}

  @media (max-width: 768px) {
    height: ${size(618)};
  }

  @media (max-width: 480px) {
    height: ${size(275)};
  }
`

export const TrainerName = styled.p`
  ${tw`cursor-pointer bg-black text-white text-center mb-0`}
  ${fontSize(16)}
  padding: ${size(5.5)} 0;
`

export const ReadMoreNewLayout = styled.p`
  ${tw`text-white text-center cursor-pointer`}
  margin-top: ${size(12)};
`

export const GymCommonTitle = styled.p`
  ${tw`uppercase text-center text-white font-bold relative`}
  ${fontSize(30)}
  padding: 0 ${size(16)};
  padding-bottom: ${size(25)};
  &::before {
    content: '';
    width: 100%;
    max-width: ${size(70)};
    height: ${size(4)};
    ${tw`bg-blue__deep absolute`}
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
  }
  
  @media (max-width: 480px) {
    ${tw`text-left`}
    padding-bottom: ${size(20)};
    padding-left: 0;
    
     &::before {
      content: '';
      width: 100%;
      max-width: ${size(70)};
      height: ${size(4)};
      ${tw`bg-blue__deep absolute`}
      left: ${size(52)};
      bottom: 0;
      transform: translate(-50%, -50%);
    }
  }
`
